<template>
<CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Active Cards</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
              </CCol>
              <CCol md="2"> 

                   <b-dropdown variant="primary" text="Actions" size="md" style="width:120px">
                      <router-link :to='"/prepaidtopup/active/add"' tag="b-dropdown-item" > Add Card </router-link>
                        <b-dropdown-item v-on:click="actionBurn()" >Burn Cards</b-dropdown-item>
                        <b-dropdown-item v-on:click="showFormPasswd()" >Download</b-dropdown-item>
                        <!-- <b-dropdown-item>
                          <download-excel
                                class="b-button"
                                :data="json_data"
                                :fields="json_fields"
                                worksheet="Active-CArds"
                                name="active_cards.xls"
                              >
                            Download Excel 
                          </download-excel>
                        </b-dropdown-item> -->
                   </b-dropdown>

              </CCol>             
          </CRow>
          <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol>                
          </CRow>
          <CRow>
                <CCol col="12" class="text-center">                   
                  <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
                </CCol> 
          </CRow>
          <CRow>
                  <CCol col="12" class="text-center"> 
                      <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                          <b-card border-variant="dark" header="Protected Excel Password" align="center">
                          <b-card-text>
                              <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                          </b-card-text>                                                                      
                          <b-button-group>
                            <b-button v-on:click="actionCancel()">Cancel</b-button>
                            <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                          </b-button-group>                                   
                          </b-card>
                      </b-card-group>
                  </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody>
          <div class="table-responsive table">

              <b-row>
                  <b-col sm="12">                    
                      <label class="form-checkbox">
                        <input type="checkbox" v-model="selectAll" @click="select"> Select All 
                      </label> 
                  </b-col>                                  
               </b-row><br/>


	        <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">
 
            <template v-slot:cell(all)="{ item }">
                <label class="form-checkbox">
                <input type="checkbox" :value="item.cardNumber" v-model="selected">
                <i class="form-icon"></i>
                </label>
            </template>


            <template v-slot:cell(createDate)="{ item }"> 
  	              {{ item.createDate | dateWithTime }}
  	        </template> 

            <template v-slot:cell(actions)="{ item }">
            <b-dropdown variant="info" text="Actions" size="md">
              <router-link :to='"/merchant/" + item.merchantIdentify.userId' tag="b-dropdown-item" > Detail </router-link>
            </b-dropdown>
            </template>
	        </b-table> 

          </div>

          <b-row>
            <b-col sm="3"> 
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>  

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>      

</div>
</template>

<script>
import TransactService from '@/api/TransactService.js';
import ReportService from '@/api/ReportService.js';
import JsonExcel from "vue-json-excel";
import moment from 'moment';
//ROLE_PREPAID_CARD

 export default {
    name: "active-cards",
    components: {
        'downloadExcel' : JsonExcel           
    },    
    data() {
      return {
        selected: [],
        selectAll: false,
        json_meta: [
              [
                {
                  key: "charset",
                  value: "utf-8",
                },
              ],
            ],
        json_fields:'',
        json_data:'',
        items:[],             
        fields: [  
          {
            key: 'all',
            label : 'Select'            
          },       	  
          {
            key: 'cardNumber',
            label : 'Card Number',
            sortable: true
          }, 
          {            
            key: 'amount',
            label : 'Amount',
            sortable: true
          },   
          {
            key: 'currency',
            label : 'Currency',
            sortable: true
          },  
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },        
          {
            key: 'expiryDate',
            label : 'Expired Date',
            sortable: true
          }
               
        ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        cbactive:false,
        columns : [],
        data : [],
        formPasswd:false,
        spinner:false,
        filename:"active-cards.xlsx",
        password:"",
        seen:false,
        msg :'',
        color:''
      };
    },
    directives: {
        dateSubstr: {
            update(el) {
                var temp = el.value.toString().split(".");
                el.value =  temp[0].replace("T"," ");
            },
        },        
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)         
        },

        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10) + '...'            
        }


      },

    created () {  
      this.getActivePrepaidTopup();
      this.setDefaultPasswd(); 
      this.setColumns();
    },
    methods: { 

      showFormPasswd(){
         this.formPasswd = true;
      },

      actionCancel(){
          this.formPasswd = false;
      },

      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },

      setColumns(){
        var fields = this.fields; let row = {}; var cols = [];
        for (var key in fields) {  //console.log(fields[key].key);
           if(fields[key].key !== "all"){      
              row['label'] = fields[key].label;
              row['field'] = fields[key].key;
              var temp = JSON.stringify(row); cols.push(temp);
           }
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },

      setDataRows(resp){

          for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === "createDate" || key2 === "expiryDate"){ 
                       //resp[key][keydate] = this.dateWithTime(resp2[key2]);
                       var strdate = resp2[key2]; 
                       var dt = new Date(strdate);
                       resp[key][key2] = dt;   
                    }                   
                 }
            }

          //console.log(resp);

          this.data  = resp;
      }, 

      generateWorkbook(){

         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;        
         var heads = []; var temp =[]; var data=[];
         let params = {};   var colkey =[];      

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          } 
          data.push(heads);  var jum = heads.length;          

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3]; 
                  if(key3==="createDate" || key3=="expiryDate"){
                    var val = new Date(val).toISOString().substr(0, 19).replace("T"," ");
                  }                
                  var ckey = colkey[key4]; 
                  if(key3===ckey){  init[key4] = val; }              
                }
              }
              data.push(init); 
          } 

         //console.log(data); 

         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click(); this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      select() {
        this.selected = [];
        if (!this.selectAll) {
          for (let i in this.items) {
            this.selected.push(this.items[i].cardNumber);
            //console.log(this.items[i].cardNumber);
          }
        }       
      },

      actionBurn(){
          let params = {}; var msg = '';       
          params['cardNumber'] = this.selected;

          if(this.selected.length > 0){  
            this.removeCardPrepaidTopup(params); 
            this.selected= [];           
          } else {           
            this.msg   = 'Cards Not Selected!'; 
            this.color = 'warning'; this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
          }
      },

      dateSubstr(value){
             if(!value) return ''
             value = value.toString()
             value = value.split(".") 
             return value[0].replace("T"," ")            
      },   

      removeCardPrepaidTopup: function(params) { 
           TransactService.removeCardPrepaidTopup(params).then(resp => { //console.log(resp); 

              if(resp.message=='Success'){
                this.msg   = 'Removed Card Successfully '; 
                this.color = 'success';  this.seen  = true;
                this.getActivePrepaidTopup();
              } else {
                this.msg   = resp.data.message; 
                this.color = 'warning'; this.seen  = true;                
              }

              setTimeout( () =>  this.seen=false , 5000);

           }, error => {
            console.log('error');    
          });
      },

      getActivePrepaidTopup: function() {
        TransactService.getActivePrepaidTopup().then(resp => {  //console.log(resp); 

                let column  = {
                  "Card Number"    : "cardNumber", 
                  "Amount"         : "amount",
                  "Currency"       : "currency",
                  "Created Date"   : "createDate", 
                  "Expired Date"   : "expiryDate"
                } 

                this.json_fields = column;  
                let row = []; let rows = [];

                for (var key in resp) { 
                    var temp = resp[key];                   
                      let row = {
                          "cardNumber"    : temp.cardNumber, 
                          "amount"        : temp.amount,                         
                          "currency"      : temp.currency,                        
                          "createDate"    : temp.createDate,  
                          "expiryDate"    : temp.expiryDate
                                                 
                       };
                      rows.push(row);                 
                }

                this.json_data = rows;      this.items = resp; 
                this.setDataRows(rows); 

           
        }, error => {
          this.loading = false;
        });
      },

    },
    mounted() { 
       
    },   
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>